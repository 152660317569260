import React from "react";
import { Box, Container, ButtonBase } from "@material-ui/core";

import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useHomeStyles from "../styles";
import PartnerRolling from "src/components/public/lists/SliderList";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";

import MainOBJ from "@assets/images/public/screen/HomeScreen/main-visual/main-obj.png";

export default function MainVisualSection() {
  const classes = useHomeStyles();

  return (
    <Box className={classes.visual_section} component="section">
      <Box className={classes.visual_section_inner}>
        <Container className={classes.container}>
          <Box className="visual_section_inner_box">
            <GoonoTypography
              type="h1"
              preLine
              keepAll
              className={"visual_section_inner_title"}
            >
              연구노트는
              <strong> 구노</strong>
              <br />
              문서 보안·공유는
              <strong> 스트롱박스</strong>
            </GoonoTypography>

            <GoonoTypography
              preLine
              className={"visual_section_inner_desc"}
              keepAll
              children={
                "ISO, GS 인증 등 국내외 표준을 충족하는 서비스로 \n 국내 2,000곳 이상의 조직에 최고의 기술을 제공합니다."
              }
            />
            <ButtonBase
              className="visual_section_button"
              onClick={() =>
                window.open(`${process.env.REACT_APP_SERVICE_URL!}`, "_blank")
              }
            >
              시작하기
              <KeyboardArrowRightRoundedIcon />
            </ButtonBase>
          </Box>
          <Box className="visual_section_inner_obj_box">
            <img src={MainOBJ} alt="" />
          </Box>
        </Container>
      </Box>
      <Box className={"rolling_box"}>
        <PartnerRolling />
      </Box>
    </Box>
  );
}
